import { ImageButton } from "../button/ImageButton";
import { useEffect, useState } from "react";
import { AuthForm } from "../form/AuthForm";
import HomeJoin from "./HomeJoin";
import HomeAdvantage from "./HomeAdvantage";
import { useTranslation } from "react-i18next";
import Separator from "../Separator";

const HomePageLastEdition = ({ onOpenImageViewer }: { onOpenImageViewer: (index: number) => void }) => {
    const { t } = useTranslation();
    const [isMobile, setMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        window.onresize = () => {
            setMobile(window.innerWidth < 992)
        };
    }, []);

    return <section className="HomePage__lastEditions">
        <div className="custom-container">
            <div style={{ transform: 'translateY(-1%)' }} className="HomePage__about__content d-flex mt-5 w-100">
                <div className="d-flex flex-column p-4 justify-content-start align-items-start">
                    <div className="mt-4">
                        <h3>{t('joinEsports.part1')} <br className="d-none d-lg-block" />
                            {t('joinEsports.part2')}</h3>
                    </div>
                    <section className="col-12 col-lg-6">
                        <p>
                            {t('joinEsports.part3')}
                        </p>
                        <p>
                            {t('joinEsports.part4')}
                        </p>
                    </section>
                    <Separator />
                </div>
                {!isMobile && <div id="authForm" className="d-none d-lg-block registerForm position-absolute p-3 me-5">
                    <AuthForm />
                </div>}
            </div>

            {isMobile && <div id="authForm" className="d-block d-lg-none registerForm p-3 p-lg-0 mx-auto mb-5 mb-lg-0">
                <AuthForm />
            </div>}


            <div className="becomeAGamer mb-5 text-center d-flex flex-column justify-content-center align-items-center gap-5">
                <div className="d-flex mt-5 flex-column justify-content-center align-items-center">
                    <h4 className="text-yellow">{t('becomePro.title1')}</h4>
                    <p className="col-12 col-lg-6">
                        {t('becomePro.part1')}
                    </p>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center gap-3">
                    <div className="gamer-rule p-1 rounded d-flex justify-content-center align-items-center flex-column gap-2">
                        <img src={require('../../images/icons/user-plus.png')} alt="" />
                        <h6 className="text-yellow">{t('becomePro.title2')}</h6>
                        <p>
                            {t('becomePro.part2')}
                        </p>
                    </div>
                    <div className="gamer-rule p-1 rounded d-flex justify-content-center align-items-center flex-column gap-2">
                        <img src={require('../../images/icons/user-group.png')} alt="" />
                        <h6 className="text-yellow">{t('becomePro.title3')}</h6>
                        <p>
                            {t('becomePro.part3')}
                        </p>
                    </div>
                    <div className="gamer-rule p-1 rounded d-flex justify-content-center align-items-center flex-column gap-2">
                        <img src={require('../../images/icons/cup.png')} alt="" />
                        <h6 className="text-yellow">{t('becomePro.title4')}</h6>
                        <p>
                            {t('becomePro.part4')}
                        </p>
                    </div>
                </div>
                <button data-scroll-to='#authForm' style={{ height: '3rem' }} className="esport-btn-primary mb-5 sublimation esport d-flex justify-content-center gap-2 align-items-center">
                    <img src={require('../../images/icons/game.png')} alt="" />
                    {t('Register')}
                </button>
            </div>

            <HomeJoin />
            <HomeAdvantage />

            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 mt-md-0">
                <h1 className="text-primary mt-5">{t('lastEditions.part1')} <br className="d-none d -lg-block" />{t('lastEditions.part2')}</h1>
                <div className="align-self-end mb-3"><button onClick={() => { onOpenImageViewer(1) }} className="esport esport-btn-default p-2">{t('lastEditions.part3')}</button></div>
            </div>

            <div className="d-flex flex-column gap-1 gap-md-3">
                <div className="d-flex justify-content-between gap-1 gap-md-2 w-100">
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(38) }} image={require("../../images/last-editions/36.jpg")} />
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(40) }} image={require("../../images/last-editions/40.jpg")} />
                    {/* <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(20) }} image={require("../../images/last-editions/20.jpeg")} /> */}
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(29) }} image={require("../../images/last-editions/29.jpg")} />
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(22) }} image={require("../../images/last-editions/22.jpeg")} />

                </div>
                <div className="d-flex justify-content-between gap-1 gap-md-2">
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(39) }} image={require("../../images/last-editions/39.jpg")} />
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(30) }} image={require("../../images/last-editions/30.jpg")} />
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(41) }} image={require("../../images/last-editions/41.jpg")} />
                    {/* <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(12) }} image={require("../../images/last-editions/13.jpeg")} /> */}
                    <ImageButton className="img-fluid" onClick={() => { onOpenImageViewer(21) }} image={require("../../images/last-editions/21.jpeg")} />

                </div>
            </div>

        </div>
    </section>
}

export default HomePageLastEdition;