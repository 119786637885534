import { useTranslation } from "react-i18next";
import Footer from "../Footer";

const HomePageGame = () => {
    const { t } = useTranslation();

    return <section className="HomePage__game no-min-height d-flex flex-column align-items-center align-items-lg-start">
        <div style={{ marginTop: '0', marginBottom: '0', border: '1px solid transparent' }} className="custom-container">
            <div className="d-flex mt-0 flex-column mt-lg-5 mb-5 mb-lg-0 justify-content-center gap-3">
                <h2 className="text-primary mt-5 mt-md-2 mt-lg-0">{t('faq.title')}</h2>
                
                <div className="HomePage__game__accordion accordion mt-0 mt-lg-5 mb-1 mb-md-5 mb-lg-0" id="accordionExample">
                    {
                        Array(7).fill(0).map((_, key) => {
                            return <div key={t(`faq.title.q${key}`)} className="accordion-item">
                                <h2 className="accordion-header" id={"heading-" + key}>
                                    <button className={"accordion-button " + (key === 0 ? '' : 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + key} aria-expanded="false" aria-controls={"collapse-" + key}>
                                        <span className="text-primmary">
                                            {t(`faq.content.q${key+1}`)}
                                        </span>
                                    </button>
                                </h2>
                                <div id={"collapse-" + key} className={"accordion-collapse collapse" + (key === 0 ? ' show' : '')} aria-labelledby={"heading-" + key} data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    {t(`faq.content.r${key+1}`)}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
        <Footer/>
    </section>
}
export default HomePageGame;