import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils/utils";

const PriceDetail = ({ price, fee }: { price: any, fee: any }) => {
    const serviceFee = (fee * price) / 100;
    const total = serviceFee + price;

    const { t } = useTranslation();


    return <div className="d-flex border-bottom small text-muted">
        <div className="d-flex flex-column">
            <div className="text-white-50">
            {t('Price')} : {formatPrice(price)}
            </div>
            <div className="text-white-50">
                {t('Service fee')} : {formatPrice((serviceFee))}
            </div>
            <div className="text-white-50">
                Total : {formatPrice((total))}
            </div>
        </div>
    </div>
}

export default PriceDetail;