import '../../styles/component/Navbar.css'
import logo from '../../images/logo.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const Navbar = () => {

    const { t, i18n } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const handleNavigateToAuth = () => {
        if (location.pathname !== '/') {
            localStorage.setItem('navigateToAuth', 'true');
            navigate('/');
        }
    }


    return <nav className="Navbar navbar navbar-expand-lg navbar-dark d-flex justify-content-center align-items-start">
        <div className="Navbar_content w-100 p-2 d-flex justify-content-between align-items-center">
            <a className="navbar-brand" href="/">
                <img width={'90px'} className='' src={logo} alt='E-Sport Game 2023' />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="Navbar_content__container collapse navbar-collapse p-2 p-lg-0" id="navbarSupportedContent">
                <ul className="navbar-nav nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <NavLink to={'/'}>
                            <span className="nav-link">{t('Accueil')}</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/actuality'}>
                            <span className="nav-link">{t('Actuality')}</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <span onClick={handleNavigateToAuth} className="nav-link toAuthForm" data-scroll-to="#authForm">{t("Auth")}</span>
                    </li>
                    <li className="nav-item">
                        <NavLink to={'/list-of-registrants'}>
                            <span className="nav-link">{t('Liste des inscrits')}</span>
                        </NavLink>
                    </li>
                </ul>

                <LanguageSwitcher />
            </div>
        </div>
    </nav>

}

export default Navbar;