import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageViewer from 'react-simple-image-viewer';
import { ImageButton } from "../component/button/ImageButton";
import Footer from "../component/Footer";
import HomePageAbout from "../component/home/HomePageAbout";
import HomePageGame from "../component/home/HomePageGame";
import HomePageLastEdition from "../component/home/HomePageLastEdition";
import HomePageMain from "../component/home/HomePageMain";
import Navbar from "../component/navigation/Navbar";
import Separator from "../component/Separator";
import '../styles/page/HomePage.css';
import '../styles/page/AboutPage.css';

const AboutPage = () => {
    const { t } = useTranslation();
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState(
        Array(23).fill(0).map((_, key) => {
            return require(`../images/last-editions/${key + 1}.jpeg`)
        }).concat(Array(6).fill(0).map((_, key) => {
            return require(`../images/last-editions/${key + 24}.jpg`)
        }))
    );

    const navigateInTo = function (this: HTMLElement, ev: MouseEvent): any {
        const targetSelector = this.getAttribute('data-scroll-to')

        if (targetSelector)
            document.querySelector(targetSelector)?.scrollIntoView();
    }

    const openImageViewer = useCallback((index: SetStateAction<number>) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    useEffect(() => {
        document.querySelectorAll('[data-scroll-to]').forEach((node, _, __) => {
            (node as HTMLElement).addEventListener('click', navigateInTo);
        });

        return () => {
            document.querySelectorAll('[data-scroll-to]').forEach((node, _, __) => {
                (node as HTMLElement).removeEventListener('click', navigateInTo);
            });
        }
    }, []);

    useEffect(() => {
        const navigateToAuth = localStorage.getItem('navigateToAuth');

        if (navigateToAuth) {
            (document.querySelector('.nav-link.toAuthForm') as HTMLSpanElement).click();
            localStorage.removeItem('navigateToAuth');
        }
    }, []);


    return <div className="p-0 m-0">
        <div className="NavbarContainer w-100">
            <div className="custom-container">
                <Navbar />
            </div>
        </div>

        <section style={{ width: '100%' }} id="about" className="HomePage__about d-flex flex-column justify-content-center align-items-center">
            <div style={{ height: 'auto !important', marginTop: '150px' }} className="custom-container">
            <div className="HomePage__about__content mb-5 w-100 fixe d-height d-flex flex-column p-3 mt-5 justify-content-between align-items-start">
                    <div style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }} className="d-flex w-100 flex-column scrollbar">
                        <div className="">
                            <h3 className="text-primary" dangerouslySetInnerHTML={{ __html: t('PressRelease1.title').replace('BLAISE DIBOTO', '<span class="selfName">BLAISE DIBOTO</span>') }}>
                            </h3>
                            <Separator />
                        </div>
                        <div className="">
                            <p>
                                {t('PressRelease1.part1.p1')}
                                {t('PressRelease1.part1.p2')}
                                {t('PressRelease1.part1.p3')}
                                {t('PressRelease1.part1.p4')}
                                {t('PressRelease1.part1.p5')}
                                {t('PressRelease1.part1.p6')}
                            </p>
                            <span> {t('PressRelease1.part2.p1')}</span>
                            <ul className="mt-2">
                                <li>{t('PressRelease1.part2.list1.l1')}</li>
                                <li>{t('PressRelease1.part2.list1.l2')}</li>
                                <li>{t('PressRelease1.part2.list1.l3')}</li>
                                <li>{t('PressRelease1.part2.list1.l4')}</li>
                                <li>{t('PressRelease1.part2.list1.l5')}</li>
                                <li>{t('PressRelease1.part2.list1.l6')}</li>
                            </ul>

                            <p>
                                {t('PressRelease1.part3.p1')}
                                {t('PressRelease1.part3.p2')}

                                <ul className="mt-2">
                                    <li>{t('PressRelease1.part3.list1.l1')}</li>
                                    <li>{t('PressRelease1.part3.list1.l2')}</li>
                                    <li>{t('PressRelease1.part3.list1.l3')}</li>
                                    <li>{t('PressRelease1.part3.list1.l4')}</li>
                                    <li>{t('PressRelease1.part3.list1.l5')}</li>
                                    <li>{t('PressRelease1.part3.list1.l6')}</li>
                                </ul>
                            </p>

                            <p>
                                {t('PressRelease1.part3.p3')}
                                <ul className="mt-2">
                                    <li>{t('PressRelease1.part3.list2.l1')}</li>
                                    <li>{t('PressRelease1.part3.list2.l2')}</li>
                                    <li>{t('PressRelease1.part3.list2.l3')}</li>
                                    <li>{t('PressRelease1.part3.list2.l4')}</li>
                                    <li>{t('PressRelease1.part3.list2.l5')}</li>
                                    <li>{t('PressRelease1.part3.list2.l6')}</li>
                                    <li>{t('PressRelease1.part3.list2.l7')}</li>
                                    <li>{t('PressRelease1.part3.list2.l8')}</li>
                                    <li>{t('PressRelease1.part3.list2.l9')}</li>
                                </ul>
                            </p>

                            <p>
                                {t('PressRelease1.part3.p4')}
                                {t('PressRelease1.part3.p5')}
                                <ul className="my-2">
                                    <li className="p-1">{t('PressRelease1.part3.list3.l1')}</li>
                                    <li className="p-1">{t('PressRelease1.part3.list3.l2')}</li>
                                    <li className="p-1">{t('PressRelease1.part3.list3.l3')}</li>
                                    <li className="p-1">{t('PressRelease1.part3.list3.l4')}</li>
                                    <li className="p-1">{t('PressRelease1.part3.list3.l5')}</li>
                                    <li className="p-1">{t('PressRelease1.part3.list3.l6')}</li>
                                    <li className="p-1">{t('PressRelease1.part3.list3.l7')}</li>
                                </ul>
                                {t('PressRelease1.part3.p6')}
                            </p>

                            <p>
                                {t('PressRelease1.part3.p7')}
                            </p>
                            <p>
                                {t('PressRelease1.part3.p8')}
                            </p>
                            <p>
                                {t('PressRelease1.part3.p9')}
                            </p>


                            {/* <p dangerouslySetInnerHTML={{ __html: t('aboutDescription.part4').replace('https://cameroon-esports.com/', '<a href="https://cameroon-esports.com/">https://cameroon-esports.com/</a>') }}>
                            </p> */}

                        </div>
                        <img className="d-block d-lg-none" src={require('./../images/blaise-diboto-certification.jpg')} alt="Blaise Diboto Certification" />

                    </div>
                </div>
                <div className="HomePage__about__content mb-5 w-100 fixe d-height d-flex flex-column p-3 mt-5 justify-content-between align-items-start">
                    <div style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }} className="d-flex flex-column scrollbar">
                        <div>
                            <h4 className="text-primary">News</h4>
                            <Separator />
                        </div>
                        <section>
                            <div>
                                <h6>{t('article1.title1')}</h6>
                                <p>
                                    {t('article1.part1')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('article1.title2')}</h6>
                                <p>
                                    {t('article1.part2')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('article1.title3')}</h6>
                                <p>
                                    {t('article1.part3')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('article1.title4')}</h6>
                                <p>
                                    {t('article1.part4')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('article1.title5')}</h6>
                                <p>
                                    <ul>
                                        <li>{t('article1.part5.p1')}</li>
                                        <li>{t('article1.part5.p2')}</li>
                                        <li>{t('article1.part5.p3')}</li>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <h6>{t('article1.title6')}</h6>
                                <p>
                                    {t('article1.part6.p1')}
                                    <br />
                                    <a href={t('article1.part6.p2') as string}> {t('article1.part6.p2')}</a>
                                    <br />
                                    {t('article1.part6.p3')}
                                    <br />
                                    <a href={t('article1.part6.p4') as string}> {t('article1.part6.p4')}</a>
                                    <br />
                                    {t('article1.part6.p5')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('article1.title7')}</h6>
                            </div>


                        </section>
                    </div>
                </div>
                <div className="rounded HomePage__about__esport__content shadow-lg w-100 d-flex flex-column p-3 justify-content-between align-items-start">
                    <div style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }} className="d-flex w-100 flex-column scrollbar">
                        <div className="">
                            <h3 className="text-primary" dangerouslySetInnerHTML={{ __html: t('aboutDescription.title').replace('BLAISE DIBOTO', '<span class="selfName">BLAISE DIBOTO</span>') }}>

                            </h3>
                            <div className='d-flex gap-2'>
                                <ImageButton style={{ width: 'max-content' }} image={require('./../images/blaise-diboto.jpg')} onClick={function (): void { }} />
                                <ImageButton className="d-none d-lg-block" style={{ width: 'max-content' }} image={require('./../images/blaise-diboto-certification.jpg')} onClick={function (): void { }} />
                            </div>
                            <Separator />
                        </div>
                        <div className="">
                            <p>
                                {t('aboutDescription.part1')}
                            </p>
                            <p>
                                {t('aboutDescription.part2')}
                            </p>
                            <p>
                                {t('aboutDescription.part3')}
                            </p>
                            <p dangerouslySetInnerHTML={{ __html: t('aboutDescription.part4').replace('https://cameroon-esports.com/', '<a href="https://cameroon-esports.com/">https://cameroon-esports.com/</a>') }}>
                            </p>
                            <p>
                                {t('aboutDescription.part5')}
                            </p>
                            <p>
                                {t('aboutDescription.part6')}
                            </p>
                            <p>
                                {t('aboutDescription.part7')}
                            </p>
                            <p>
                                {t('aboutDescription.part8')}
                            </p>
                            <p>
                                {t('aboutDescription.part9')}
                            </p>
                        </div>
                        <img className="d-block d-lg-none" src={require('./../images/blaise-diboto-certification.jpg')} alt="Blaise Diboto Certification" />

                    </div>
                </div>

            </div>
        </section>

        <Footer />
        {isViewerOpen && (
            <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
            />
        )}
    </div>
}

export { AboutPage };