import { AnyAction } from "@reduxjs/toolkit";
import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useForm from "../../hook/useForm";
import { Member } from "../../models/Member";
import { setCurrentUser } from "../../redux/slices/userSlice";
import { UserService } from "../../services/UserService";
import { FormOptions } from "./AuthForm";
import { SelectCity } from "./SelectCity";

const RegisterForm = (options: FormOptions) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const submitBtnRef = useRef<HTMLButtonElement>(null);
    const verifyOTPBtnRef = useRef<HTMLButtonElement>(null);

    const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
        // { last_name: 'Nguetcha', first_name: 'Alex', email: 'nguetchaalex@gmail.com', city: 'Douala', address: 'Oyack', phone: '695904403', password: 'password', },
        { last_name: '', first_name: '', city: '', phone: '', password: '', status: '' },
        (name: string, value: string | any[]) => {
            if (name === 'last_name') {
                if (value.length < 3) return 'incorrect last name';
            }
            if (name === 'status') {
                if (value.length < 3) return 'incorrect status';
            }
            if (name === 'first_name') {
                if (value.length < 3) return 'incorrect first name';
            }
            if (name === 'city') {
                if (value.length < 2) return 'incorrect city';
            }
            if (name === 'phone') {
                if (!(/^6[0-9]\d{7}$/.test(value as string))) return 'incorrect phone number';
            }
            if (name === 'password') {
                if (value.length < 6) return 'incorrect password';
            }
            return null;
        }
    );

    const [needOTPValidation, setNeedOTPValidation] = useState<boolean>(
        localStorage.getItem('otpConfirmation') !== null
    );
    const [onLoadingForSendNewOTP, setOnLoadingForSendNewOTP] = useState<boolean>(false);
    const [loaderOTP, setLoaderOTP] = useState<number>(60);
    const [otpIntervalId, setOtpIntervalId] = useState<NodeJS.Timer>();
    const [onSendingOTP, setOnSendingOTP] = useState(false);
    const [onValidatingOTP, setOnValidatingOTP] = useState(false);
    const [onValidatingTeamRegistration, setOnValidatingTeamRegistration] = useState(false);

    const [otpError, setOTPError] = useState<string | null>(null);

    const startCheckingForNewOTP = () => {
        setOnLoadingForSendNewOTP(true);
        setLoaderOTP(60);
        setOtpIntervalId(setInterval(() => {
            setLoaderOTP((value) => {
                if (value - 1 <= 0) {
                    clearInterval(otpIntervalId);
                    setOnLoadingForSendNewOTP(false);
                }
                return value - 1;
            });
        }, 1000));
    }

    const handleResendOTP = async (e: any) => {
        if (onLoadingForSendNewOTP || onSendingOTP) {
            return;
        }
        setOnValidatingOTP(true);
        UserService.resendOTP().then(() => {
            toast.success(t('A new OTP code has been sent to you.'));
            startCheckingForNewOTP();
        }).catch((err) => {
            toast.warning(t('Failed to send OTP code'));
        }).finally(() => {
            setOnValidatingOTP(false);
        });
    }

    const ___handleSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (formValues.status === 'Team') {
            setOnValidatingTeamRegistration(true);
        } else {
            await __handleSubmit(e);
        }
    }

    const __handleSubmit = async (e: any) => {
        setOnValidatingTeamRegistration(false);
        e.stopPropagation();
        e.preventDefault();

        const isSubmittable = handleSubmit(e)

        if (isSubmittable && submitBtnRef.current) {

            let innerHTML = submitBtnRef.current.innerHTML;
            submitBtnRef.current.setAttribute('disabled', 'true')
            submitBtnRef.current.innerHTML = innerHTML.replace(t('Register') as string, t('connecting...'));

            UserService.signup(formValues)
                .then((json) => {
                    // set the jwt token in local storage
                    localStorage.setItem("authToken", json.data.token);
                    localStorage.setItem("authTokenDate", (new Date()).getTime() as unknown as string);
                    localStorage.setItem("otpConfirmation", "true");
                    localStorage.setItem("otpPhone", formValues.phone);
                    localStorage.setItem("otpName", formValues.first_name);
                    setNeedOTPValidation(true);
                    startCheckingForNewOTP();
                }).catch((err) => {
                    const data = err.response.data
                    if (data.errors) {
                        setErrors((state: any) => {
                            return { ...state, ...data.errors };
                        });
                    }
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = innerHTML;
                });
        }
    }

    const dispatch = useDispatch();

    const handleValidateOTPCode = (e: any) => {
        let innerHTML = e.target.innerHTML;
        e.target.setAttribute('disabled', 'true');
        e.target.innerHTML = innerHTML.replace(t('Validate') as string, t('sending...'));
        UserService.verifyOTP(otp)
            .then((json) => {
                dispatch(setCurrentUser(json.data.data as Member) as unknown as AnyAction)
                localStorage.clear();
                localStorage.setItem('authToken', json.data.token);
                setTimeout(() => { navigate('/dashboard/card') }, 500);
            }).catch((err) => {
                const data = err.response.data;
                if (data.errors) {
                    setOTPError(data.errors);
                }
            }).finally(() => {
                e.target.removeAttribute("disabled");
                e.target.innerHTML = t('Validate');
            });
    }

    const handleClose = () => {
        localStorage.clear();
        setNeedOTPValidation(false);
        setOnLoadingForSendNewOTP(false)
        setOnValidatingTeamRegistration(false)
    }

    const [otp, setOtp] = useState('');


    return <>
        <form id="registerForm" className="auth-form register-form auth-form-show d-flex flex-column justify-content-start w-100">

            <h3 className="w-100 text-center">{t('Register')}</h3>

            <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="last_name" className="col-form-label required">{t('First name')}</label>
                    <div className="col">
                        <input onChange={handleChange} value={formValues.last_name} type="text" className="w-100" name="last_name" placeholder={'' + t('First name')} />
                    </div>
                    {errors.last_name && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.last_name}</span>}
                </div>
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="first_name" className="col-form-label required">{t('Last name')}</label>
                    <div className="col">
                        <input onChange={handleChange} value={formValues.first_name} type="text" className="w-100" name="first_name" placeholder={'' + t('Last name')} />
                    </div>
                    {errors.first_name && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.first_name}</span>}
                </div>
            </div>

            <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="phone" className="col-form-label required">{t('Phone number')}</label>
                    <div className="col">
                        <input onChange={handleChange} value={formValues.phone} type="phone" className="w-100" name="phone" placeholder={'' + t('Phone number')} />
                    </div>
                    {errors.phone && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.phone}</span>}
                </div>
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="city" className="col-form-label required">{t('City')}</label>
                    <div className="col">
                        <select onChange={handleChange} value={formValues.city} className="w-100" name="city">
                            <option value={0}>{t('Select a city')}</option>
                            <SelectCity />
                        </select>
                        {errors.city && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.city}</span>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="email" className="col-form-label">{t('Email')}</label>
                    <div className="col">
                        <input onChange={handleChange} value={formValues.email} type="email" className="w-100" name="email" placeholder="johndoe@esport.com" />
                    </div>
                    {errors.email && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.email}</span>}
                </div>
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="status" className="col-form-label required">{t('Status')}</label>
                    <div className="col">
                        <input className="w-100" type={'text'} onChange={handleChange} value={formValues.status} list="statuses" id="status" name="status" />
                        <datalist id="statuses">
                            <option value="Team">Team</option>
                            <option value="Gamers"></option>
                            <option value="Video game developer"></option>
                            <option value="Enterprise"></option>
                            <option value="Staff"></option>
                            <option value="Sympathizer"></option>
                            <option value="Others"></option>
                        </datalist>
                        {errors.status && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.status}</span>}
                    </div>

                </div>
            </div>


            <div className="mb-3 d-flex flex-column">
                <label htmlFor="password" className="col-form-label required">{t('Password')}</label>
                <div className="col">
                    <input onChange={handleChange} value={formValues.password} type="password" className="w-100" name="password" placeholder="******" />
                </div>
                {errors.password && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.password}</span>}
            </div>

            <button onClick={(e) => ___handleSubmit(e)} ref={submitBtnRef} type="submit" className="esport-btn-primary esport d-flex justify-content-center gap-2 align-items-center">
                <img src={require('../../images/icons/game.png')} alt="" />
                {t('Register')}
            </button>
            <div className="d-flex justify-content-center mt-3">
                {t('Already have an account')} ? <span onClick={() => options.onToggle('.login-form')} className="text-green cursor-pointer ms-1"> {t('Login')} </span>
            </div>

        </form>

        <Modal
            backdrop="static"
            keyboard={false}
            centered
            show={needOTPValidation}
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>E-Sports Gaming 2023</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('Dear')} {' '} {localStorage.getItem('otpName')},<br />
                <p>{t('confirmOTP.part5')}<br />{t('confirmOTP.part6')}</p>

                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    inputStyle={{ width: '25%', border: '1px solid white' }}
                    renderInput={(props) => <input {...props} />}
                />
                {otpError && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {otpError}</span>}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between cursor-pointer">
                <div className="d-flex gap-2">
                    <div onClick={handleResendOTP} className={"resendOTPCode " + (onLoadingForSendNewOTP ? 'disabled' : '')}>
                        {!onSendingOTP ? t('resendOTPCode') : t('sending...')}
                    </div>
                    {onLoadingForSendNewOTP ? ` ${loaderOTP}s` : ''}
                </div>
                <Button ref={verifyOTPBtnRef} disabled={otp.length !== 4} className="esport px-5" variant="secondary" onClick={handleValidateOTPCode}>
                    {!onValidatingOTP ? t('Validate') : t('sending...')}
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            backdrop="static"
            keyboard={false}
            centered
            show={onValidatingTeamRegistration}
            onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                When you register as a team, you can add up to 10 members. The team registration fee is 70,000 FCFA, with an additional 10,000 FCFA fee per member. Expand your team's strength and expertise while sharing the responsibilities. Choose your team wisely and embark on the path to success together!
                </p>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end cursor-pointer">
                <Button className="esport px-5" variant="secondary" onClick={__handleSubmit}>
                    I understand
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export { RegisterForm };