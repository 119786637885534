import { useTranslation } from "react-i18next";

export type GameName = 'tekken 7' | 'street fighter 5' | 'e-football' | 'just-dance';
export type CompetitionName = 'jeux universitaires' | 'world cup' | 'world cup romania';

export interface ParticipationOptions {
    videoUrl: string;
    gameName: GameName,
    gameDescription: string,
    buttonText?: string,
    onParticipate: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Participation = (options: ParticipationOptions) => {
    const { t } = useTranslation();
    
    return <div className="p-2 opacity-animation d-flex flex-column flex-md-row justify-content-start gap-4">
        <video autoPlay muted loop className="Participation__Video rounded">
            <source src={options.videoUrl} />
        </video>
        <div className="d-flex flex-column">
            <h1 className="text-yellow text-capitalize">{options.gameName}</h1>
            <p>
                {options.gameDescription}
            </p>
            <button onClick={options.onParticipate} className="esport esport-btn-primary p-0">{options.buttonText ?? t('I participate')}</button>
        </div>
    </div>
}

export { Participation };