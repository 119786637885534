import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {

    const { i18n } = useTranslation();

    const handleChangeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

    return <div className='language-change d-flex justify-content-between gap-1'>
        <span onClick={() => handleChangeLanguage('fr-FR')} className={'language-change__item language-change__fr' + (i18n.resolvedLanguage === 'fr' ? ' language-change__item--active' : '')}>FR</span>
        <span>|</span>
        <span onClick={() => handleChangeLanguage('en-US')} className={'language-change__item language-change__en' + (i18n.resolvedLanguage === 'en' ? ' language-change__item--active' : '')}>EN</span>
    </div>
}

export default LanguageSwitcher;