import '../../styles/component/PaymentMethod.css';

export const PaymentMethod = ({ onSelect, className }: { onSelect: any, className?: any }) => {

    const handleSelect = (e: any) => {
        let value = e.target.value;
        if (value === 'om') value = { value, name: "Orange Money" }
        if (value === 'momo') value = { value, name: "MTN Money" }
        if (value === 'visa') value = { value, name: "VISA" }
        if (value === 'mastercard') value = { value, name: "Mastercard" }
        onSelect(value)
    }

    return <div className={"PaymentMethod " + className}>
        <form className='d-flex gap-2'>
            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlFor='om'>
                    <img alt='' src={require('../../images/payment/om.jpg')} />
                </label>
                <input onClick={handleSelect} value={'om'} className="form-check-input" type="radio" id="om" name='method' />
            </div>

            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlFor='momo'>
                    <img alt='momo' src={require('../../images/payment/momo.webp')} />
                </label>
                <input onClick={handleSelect} value={'momo'} className="form-check-input" type="radio" id="momo" name='method' />
            </div>

            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlFor='visa'>
                    <img alt='visa' src={require('../../images/payment/visa.jpg')} />
                </label>
                <input onClick={handleSelect} value={'visa'} className="form-check-input" type="radio" id="visa" name='method' />
            </div>

            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlFor='mastercard'>
                    <img alt='mastercard' src={require('../../images/payment/mastercard.webp')} />
                </label>
                <input onClick={handleSelect} value={'mastercard'} className="form-check-input" type="radio" id="mastercard" name='method' />
            </div>

        </form>
    </div>

}

