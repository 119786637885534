import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import useForm from "../../hook/useForm";
import { UserService } from "../../services/UserService";
import { getHostName } from "../../utils/utils";
import { FormOptions } from "./AuthForm";


const ForgotPasswordForm = (options: FormOptions) => {
    const { t } = useTranslation();
    const submitBtnRef = useRef<HTMLButtonElement>(null);
    const [message, setMessage] = useState<string | null>(null);

    const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
        { phone: '' },
        (name: string, value: string | any[]) => {
            if (name === 'phone') {
                if (!(/^6[0-9]\d{7}$/.test(value as string))) return 'incorrect phone number';
            }
            return null;
        }
    );

    const __handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const isSubmittable = handleSubmit(e);
        if (isSubmittable && submitBtnRef.current) {
            let innerHTML = submitBtnRef.current.innerHTML;
            submitBtnRef.current.setAttribute('disabled', 'true')
            submitBtnRef.current.innerHTML = innerHTML.replace(t('Send') as string, t('sending...'));

            const data = {
                ...formValues,
                baseLink: getHostName() + '/'
            };
            UserService.askNewPasswordLink(data).
                then((json) => {

                    if (json.data.errors) {
                        setErrors(json.data.errors)
                    } else {
                        setMessage(t('The reset link has been sent to your email/phone please verify it.'))
                    }
                }).catch((err) => {
                    const data = err.response.data
                    if (data.errors) {
                        setErrors(data.errors);
                    }
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = innerHTML;
                });
        } else {
            e.target.removeAttribute("disabled");
        }
    }

    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => setShow(false);

    return <>
        <form id="loginForm" className="auth-form forgot-form d-flex flex-column justify-content-start w-100">

            <h3 className="w-100 text-center">{t('Forgot Password')}</h3>
            {message === null ? <div className="alert alert-warning" role="alert">
                Veuillez fournir votre numéro de téléphone afin que nous puissions vous envoyer un lien de réinitialisation.
            </div> : <div className="alert alert-success"><i style={{ fontSize: '.8em' }} className='fa fa-check-circle'></i> {message}</div>}

            {errors.length > 0 && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors[0]}</span>}

            {errors.phone && <span className='form-error mb-2'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.phone}</span>}
            <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                <div className="mb-3 d-flex flex-column w-100">
                    <label htmlFor="phone" className="col-form-label">{t('Phone number')}</label>
                    <div className="col">
                        <input onChange={handleChange} value={formValues.phone} type="phone" className="w-100" name="phone" placeholder={'' + t('Phone number')} />
                    </div>
                </div>
            </div>

            <button onClick={(e) => __handleSubmit(e)} ref={submitBtnRef} type="submit" className="esport-btn-primary esport d-flex justify-content-center gap-2 align-items-center">
                <img src={require('../../images/icons/game.png')} alt="" />
                {t('Send')}
            </button>
            <div className="d-flex justify-content-center mt-3">
                {t('No account')} ? <span onClick={() => options.onToggle('.register-form')} className="text-green cursor-pointer ms-1"> {t('Register')} </span>
            </div>

        </form>
    </>
}

export { ForgotPasswordForm };