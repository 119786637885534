import { useEffect, useRef, useState } from "react";
import AdminContainer from "../component/AdminContainer";
import '../styles/page/CardPage.css';
import '../styles/page/TeamPage.css';
import { UserState } from "../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { parseReadableDate } from "../utils/utils";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import CircularLoader from "../component/loader/CircularLoader";
import { Member } from "../models/Member";
import { AnyAction } from "@reduxjs/toolkit";
import { MemberState, getMembers } from "../redux/slices/memberSlice";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";
import { SelectCity } from "../component/form/SelectCity";
import useForm from "../hook/useForm";
import { UserService } from "../services/UserService";

const TeamPage = () => {
    const dispatch = useDispatch();
    const userData = useSelector<Record<string, UserState>, UserState>((state) => state.user).data;
    const [onPay, setOnPay] = useState(false);
    const [activePanel, setActivePanel] = useState(0);
    const navigate = useNavigate();
    const memberData = useSelector<Record<string, MemberState>, MemberState>((state) => state.member).data;
    const [q, setQ] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [newMember, setNewMember] = useState({
        first_name: "",
        last_name: "",
        nickname: "",
        city: "",
        // Add other fields as required
    });
    const submitBtnRef = useRef<HTMLButtonElement>(null);

    const { t } = useTranslation();

    const customTextFilter = (data: Member[], search: string | null) => {
        if (search === null) {
            return data;
        }

        search = search.toLowerCase();
        return data.filter((val: { first_name: string; last_name: string; }, key: any) => {
            return val.first_name.toLowerCase().includes(search as string) || val.last_name.toLowerCase().includes(search as string)
        })
    }

    const initialFilter = (members: Member[]) => {
        return members.sort((a, b) => {
            let aDate = new Date(a.created_at)
            let bDate = new Date(b.created_at)
            return (aDate as unknown as number) - (bDate as unknown as number);
        }).sort((a, b) => {
            const _a = a.image ? 1 : 0
            const _b = b.image ? 1 : 0
            return -_a + _b;
        }).sort((a, b) => {
            const _a = a.certified ? 1 : 0
            const _b = b.certified ? 1 : 0
            return -_a + _b;
        }).filter((member) => member?.team_id === userData?.id);
    }

    const memberDataColumns = [
        {
            name: 'Profile',
            selector: (row: Member) => {
                if (row.image) {
                    return <img src={row.image} className='p-2' style={{ borderRadius: '50%', width: '70px' }} alt={row.first_name} />
                }
                return <small className="text-muted text-white-50 small" style={{ userSelect: 'none' }}>---</small>
            }
        },
        {
            name: 'Date',
            selector: (row: { created_at: any; }) => { return parseReadableDate(row.created_at, true, true) },
            sortable: true,
            sortFunction: (a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => {
                let aDate = new Date(a.created_at)
                let bDate = new Date(b.created_at)
                return (aDate as unknown as number) - (bDate as unknown as number);
            }
        },
        {
            name: t('Phone Number'),
            selector: (row: Member) => { return <span className="">{row.phone }</span> },
            sortable: true,
        },
        {
            name: t('Name'),
            selector: (row: Member) => { return <span className="text-warning">{row.first_name} {row.last_name}</span> },
            sortable: true,
        },
        {
            name: t('City'),
            selector: (row: Member) => { return row.city },
            sortable: true,
        },
    ];

    const handleAddMember = (e: any) => {
        __handleSubmit(e)
    };

    const handleOpenModal = (e: any) => {
        setShowModal(true);
    }

    const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
        // { last_name: 'Nguetcha', first_name: 'Alex', email: 'nguetchaalex@gmail.com', city: 'Douala', address: 'Oyack', phone: '695904403', password: 'password', },
        { last_name: '', first_name: '', city: '', phone: '', password: '', status: 'Gamers' },
        (name: string, value: string | any[]) => {
            if (name === 'last_name') {
                if (value.length < 3) return 'incorrect last name';
            }
            if (name === 'status') {
                if (value.length < 3) return 'incorrect status';
            }
            if (name === 'first_name') {
                if (value.length < 3) return 'incorrect first name';
            }
            if (name === 'city') {
                if (value.length < 2) return 'incorrect city';
            }
            if (name === 'phone') {
                if (!(/^6[0-9]\d{7}$/.test(value as string))) return 'incorrect phone number';
            }
            if (name === 'password') {
                if (value.length < 6) return 'incorrect password';
            }
            return null;
        }
    );

    const __handleSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        const isSubmittable = handleSubmit(e)

        if (isSubmittable && submitBtnRef.current) {
            setSubmitting(true)

            let innerHTML = submitBtnRef.current.innerHTML;
            submitBtnRef.current.setAttribute('disabled', 'true')
            submitBtnRef.current.innerHTML = innerHTML.replace(t('Register') as string, t('connecting...'));

            UserService.addToTeam(formValues)
                .then((json) => {
                    setShowModal(false);
                    dispatch(getMembers() as unknown as AnyAction);
                }).catch((err) => {
                    const data = err.response.data
                    if (data.errors) {
                        setErrors((state: any) => {
                            return { ...state, ...data.errors };
                        });
                    }
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = innerHTML;
                    setSubmitting(false)
                });
        }
    }

    useEffect(() => {
        !memberData && dispatch(getMembers() as unknown as AnyAction);
    }, [memberData, dispatch]);


    return <AdminContainer>

        <section className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="text-primary text-bold">{t('Manage your team')}</h1>
                <button onClick={handleOpenModal} className="esport esport-btn-primary sublimation p-1 px-4 text-uppercase"><i className="fa fa-plus"></i>{t('Add a member')}</button>
            </div>
            {memberData ? <>
                <div className="mb-3 d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <span className="w-100" style={{ fontSize: '2em' }}>{t('Filter')}</span>
                    <div className="filter d-flex border ms-auto">
                        <div className="searchForm d-flex justify-content-center align-items-center">
                            <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQ(e.target.value)} id="search" type={'text'} placeholder={'' + t('Search')} />
                            <div className="mx-3"><i className="fa fa-search" /></div>
                        </div>
                    </div>
                </div>
                <DataTable className='CustomDataTable'
                    columns={memberDataColumns as TableColumn<Member>[]}
                    data={initialFilter(customTextFilter(memberData.slice(), q))}
                    striped
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                />
            </> : <CircularLoader />}
        </section>

        {/* Add the Modal */}
        <Modal style={{ backdropFilter: 'blur(2px)', backgroundColor: '#00000040' }} backdrop="static"
            keyboard={false} show={showModal} onHide={() => setShowModal(!showModal)}>
            <ModalHeader>
                {t('Add a new member')}
            </ModalHeader>
            <ModalBody>
                <form id="registerForm" className="auth-form register-form auth-form-show d-flex flex-column justify-content-start w-100">

                    <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                        <div className="mb-3 d-flex flex-column w-100">
                            <label htmlFor="last_name" className="col-form-label required">{t('First name')}</label>
                            <div className="col">
                                <input onChange={handleChange} value={formValues.last_name} type="text" className="w-100" name="last_name" placeholder={'' + t('First name')} />
                            </div>
                            {errors.last_name && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.last_name}</span>}
                        </div>
                        <div className="mb-3 d-flex flex-column w-100">
                            <label htmlFor="first_name" className="col-form-label required">{t('Last name')}</label>
                            <div className="col">
                                <input onChange={handleChange} value={formValues.first_name} type="text" className="w-100" name="first_name" placeholder={'' + t('Last name')} />
                            </div>
                            {errors.first_name && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.first_name}</span>}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                        <div className="mb-3 d-flex flex-column w-100">
                            <label htmlFor="phone" className="col-form-label required">{t('Phone number')}</label>
                            <div className="col">
                                <input onChange={handleChange} value={formValues.phone} type="phone" className="w-100" name="phone" placeholder={'' + t('Phone number')} />
                            </div>
                            {errors.phone && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.phone}</span>}
                        </div>
                        <div className="mb-3 d-flex flex-column w-100">
                            <label htmlFor="city" className="col-form-label required">{t('City')}</label>
                            <div className="col">
                                <select onChange={handleChange} value={formValues.city} className="w-100" name="city">
                                    <option value={0}>{t('Select a city')}</option>
                                    <SelectCity />
                                </select>
                                {errors.city && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.city}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                        <div className="mb-3 d-flex flex-column w-100">
                            <label htmlFor="email" className="col-form-label">{t('Email')}</label>
                            <div className="col">
                                <input onChange={handleChange} value={formValues.email} type="email" className="w-100" name="email" placeholder="johndoe@esport.com" />
                            </div>
                            {errors.email && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.email}</span>}
                        </div>
                        <div className="mb-3 d-flex flex-column d-none w-100">
                            <label htmlFor="status" className="col-form-label required">{t('Status')}</label>
                            <div className="col">
                                <input className="w-100" type={'text'} onChange={handleChange} value={formValues.status} list="statuses" id="status" name="status" />
                                <datalist id="statuses">
                                    <option value="Team">Team</option>
                                    <option value="Gamers"></option>
                                    <option value="Video game developer"></option>
                                    <option value="Enterprise"></option>
                                    <option value="Staff"></option>
                                    <option value="Sympathizer"></option>
                                    <option value="Others"></option>
                                </datalist>
                                {errors.status && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.status}</span>}
                            </div>

                        </div>
                    </div>

                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="password" className="col-form-label required">{t('Password')}</label>
                        <div className="col">
                            <input onChange={handleChange} value={formValues.password} type="password" className="w-100" name="password" placeholder="******" />
                        </div>
                        {errors.password && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {errors.password}</span>}
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>
                <button disabled={submitting} className="esport esport-btn-primary sublimation p-1 px-4" onClick={() => setShowModal(false)}>
                    {t('Cancel')}
                </button>
                <button disabled={submitting} ref={submitBtnRef} className="esport esport-btn-primary sublimation p-1 px-4" onClick={handleAddMember}>
                    {submitting ? t('saving...') : t('Add')}
                </button>
            </ModalFooter>
        </Modal>

    </AdminContainer>
}

export default TeamPage;