import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import PaymentService from "../../services/PaymentService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;



export const getPayments = createAsyncThunk(
    "payments/all",
    async () => {
        const res = await PaymentService.getAll();
        return res.data;
    }
);

export const handlePayment = createAsyncThunk(
    "payments/handle",
    async (payment: any) => {
        const res = await PaymentService.handle(payment)
        return res.data;
    }
);

export const updatePayment = createAsyncThunk(
    "payments/update",
    async (payment: any) => {
        return payment;
    }
);


export interface PaymentState {
    data: any,
}

const initialState: PaymentState = {
    data: null
};

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(handlePayment.fulfilled, (state, action) => {
                state.data = action.payload;
            }).addCase(updatePayment.fulfilled, (state, action) => {
                state.data = action.payload.payment;
            });
    },
    reducers: {}
});

const { reducer } = paymentSlice;
export default reducer;