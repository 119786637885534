import { useSelector } from "react-redux";
import AdminContainer from "../component/AdminContainer";
import ProfilForm from "../component/form/ProfilForm";
import { Member } from "../models/Member";
import { UserState } from "../redux/slices/userSlice";
import '../styles/page/ProfilePage.css';


const ProfilePage = () => {
    const userData = useSelector<Record<string, UserState>, UserState>((state) => state.user).data;

    return <AdminContainer>
        <ProfilForm user={userData as Member} />
    </AdminContainer>
}

export default ProfilePage;