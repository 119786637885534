import { AnyAction } from '@reduxjs/toolkit';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Member } from '../../models/Member';
import { logoutCurrentUser } from '../../redux/slices/userSlice';
import '../../styles/component/TopBar.css';
import LanguageSwitcher from './LanguageSwitcher';

interface TopBarOptions {
    user: Member,
    onToggle: (e: any) => void,
}
export const avatarUrl = require('../../images/avatar.jpg');

const TopBar = ({ user, onToggle }: TopBarOptions) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        dispatch(logoutCurrentUser() as unknown as AnyAction).then(() => {
            setTimeout(() => { window.location.href = '/' }, 500);
        })
    }

    const handleProfile = () => {
        navigate('/dashboard/profile');
    }

    return <div className="TopBar d-flex justify-content-between align-items-center">
        <div className='d-flex gap-5'>
            <div className='TopBar__greetings gap-2 d-flex align-items-center text-uppercase'>
                <div onClick={onToggle} id='sideBarToggle' className='d-flex align-items-center justify-content-center'>
                    <i className="fa-solid fa-bars"></i>
                </div>
            </div>
        </div>

        <div className='d-flex align-items-center gap-3'>
            {/* <i className='TopBar__notifications fa fa-bell'></i> */}
            <div className="dropdown">
                <span className="dropdown-toggle cursor-pointer d-flex gap-1 align-items-center" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt={user.last_name} className='profile' src={user.image ?? avatarUrl} />
                    {user.nickname ?
                        <>
                            @{user.nickname}
                        </>
                        :
                        <>
                            {user.first_name} {user.last_name}
                        </>
                    }

                </span>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><button className="dropdown-item" onClick={handleProfile}><i className='fa fa-edit'></i> {t('Profile')}</button></li>
                    <li><button className="dropdown-item" onClick={handleLogout}><i className='fa fa-sign-out'></i> {t('Logout')}</button></li>
                </ul>
            </div>
            <LanguageSwitcher />
        </div>
    </div>
}

export default TopBar;