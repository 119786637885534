import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = () => {
    return axios.get(`payments`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const get = (id: any) => {
    return axios.get(`payments/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const handle = (payment: any) => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((token) => {
        return axios.post('handle-payment', { ...payment, _token: token.data },
            {
                headers: {
                    "X-CSRF-TOKEN": token.data,
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                }
            }).then((data) => {
                return data;
            })
    })
}


const PaymentService = {
    getAll,
    handle,
    get
}

export default PaymentService;