import { AnyAction } from "@reduxjs/toolkit";
import { useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminContainer from "../component/AdminContainer";
import { CompetitionName, GameName, Participation } from "../component/participation/Participation";
import { Member } from "../models/Member";
import { addParticipation, UserState } from "../redux/slices/userSlice";
import '../styles/page/ParticipationPage.css';


const ParticipationPage = () => {
    const { t } = useTranslation();
    const userData = useSelector<Record<string, UserState>, UserState>((state) => state.user).data;

    const [gameKey, setGameKey] = useState<GameName>('tekken 7');
    const [competitionKey, setCompetitionKey] = useState<CompetitionName>('jeux universitaires')
    const [show, setShow] = useState<boolean>(false);
    const [onSending, setOnSending] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleParticipation = () => {
        if ((!userData?.card || userData.card.length === 0)) {
            return toast.warning(
                <div>
                    {t('needToPayBefore')}
                    <span onClick={() => navigate('/dashboard/card')} className='deeplink'>{t('payNow')} <i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                </div>,
                { autoClose: false }
            )
        }
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setOnSending(true);
        const data = {
            competition: (competitionKey as string) === 'WorldCupRomania' ? 'world cup romania' : 'world cup',
            game: gameKey.slice().replaceAll(' ', ''),
            message: message,
        };
        dispatch(addParticipation(data) as unknown as AnyAction).unwrap().then((data: any) => {
            toast.success(t('Your participation has been saved'), { autoClose: false });
        }).catch((err: any) => {
            toast.warning(t('An error occurred'));
        }).finally(() => {
            setOnSending(false);
            setShow(false);
        })
    }

    const hasRegisteredToCompetition = (user: Member, competition: CompetitionName): boolean => {

        const data = userData;
        const userGame = data?.participation?.filter(
            (val) => val.competition === (competition as string)
        );

        return userGame?.length === 1

    }

    const hasRegisteredToGame = (user: Member, competition: CompetitionName, gameName: GameName | string): boolean => {

        const data = userData;
        const userGame = data?.participation?.filter(
            (val) => val.competition === (competition as string)
        );
        if (userGame?.length === 1) {

            return userGame[0].game === gameName;
        }
        return false;
    }

    return <AdminContainer>
        <section className="border-5 bounce-animation w-100 d-flex flex-column rounded p-2">
            <h3 className="text-primary my-3">{t('SendDemand')}</h3>

            <Tabs
                id="controlled-competition-tab-example"
                activeKey={competitionKey}
                onSelect={(k) => setCompetitionKey(k as CompetitionName)}
                className="mb-3 mt-2"
                fill
            >

                {/* <Tab tabClassName="Participation__Tab__Item" eventKey="jeux universitaires"
                    title={<span> {t('UniversityGames')} {hasRegisteredToCompetition(userData as Member, 'jeux universitaires') ? <i className="fa fa-check-circle"></i> : ''}</span>}>

                    <Tabs
                        id="controlled-competition2-tab-example"
                        activeKey={gameKey}
                        onSelect={(k) => setGameKey(k as GameName)}
                        className="mb-3 mt-2 px-3 pt-1"
                        fill
                    >
                        <Tab tabClassName="Participation__Tab__Item" eventKey="just-dance"
                            title={<span>Just dance {hasRegisteredToGame(userData as Member, 'just-dance') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                            <Participation
                                gameName="just-dance"
                                videoUrl={require('../videos/JustDance.mp4')}
                                gameDescription={t('JustDance')}
                                onParticipate={handleParticipation}
                            />
                        </Tab>
                        <Tab tabClassName="Participation__Tab__Item" eventKey="e-football"
                            title={<span>E-Football {hasRegisteredToGame(userData as Member, 'e-football') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                            <Participation
                                gameName="e-football"
                                videoUrl={require('../videos/E-football.mp4')}
                                gameDescription={t('EFootball')}
                                onParticipate={handleParticipation}
                            />
                        </Tab>
                    </Tabs>

                </Tab> */}
                <Tab tabClassName="Participation__Tab__Item" eventKey="WorldCupRomania"
                    title={<span> {t('WorldCupRomania')} {hasRegisteredToCompetition(userData as Member, 'world cup romania') ? <i className="fa fa-check-circle"></i> : ''}</span>}>

                    <Tabs
                        id="controlled-competition1-tab-example"
                        activeKey={gameKey}
                        onSelect={(k) => setGameKey(k as GameName)}
                        className="mb-3 mt-2 px-3 pt-1"
                        fill
                    >
                        <Tab tabClassName="Participation__Tab__Item" eventKey="tekken 7"
                            title={<span>Tekken 7 {hasRegisteredToGame(userData as Member, 'world cup romania', 'tekken7') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                            <Participation
                                gameName="tekken 7"
                                videoUrl={require('../videos/Tekken.mp4')}
                                gameDescription={t('Tekken7')}
                                onParticipate={handleParticipation}
                            />
                        </Tab>
                        <Tab tabClassName="Participation__Tab__Item" eventKey="e-football"
                            title={<span>E-Football {hasRegisteredToGame(userData as Member, 'world cup romania', 'e-football') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                            <Participation
                                gameName="e-football"
                                videoUrl={require('../videos/E-football.mp4')}
                                gameDescription={t('EFootball')}
                                onParticipate={handleParticipation}
                            />
                        </Tab>
                    </Tabs>

                </Tab>
                <Tab tabClassName="Participation__Tab__Item" eventKey="coupe du monde"
                    title={<span>{t('WorldCupCameroon')} {hasRegisteredToCompetition(userData as Member, 'world cup') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                    <Tabs
                        id="controlled-competition1-tab-example"
                        activeKey={gameKey}
                        onSelect={(k) => setGameKey(k as GameName)}
                        className="mb-3 mt-2 px-3 pt-1"
                        fill
                    >
                        <Tab tabClassName="Participation__Tab__Item" eventKey="tekken 7"
                            title={<span>Tekken 7 {hasRegisteredToGame(userData as Member, 'world cup', 'tekken7') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                            <Participation
                                gameName="tekken 7"
                                videoUrl={require('../videos/Tekken.mp4')}
                                gameDescription={t('Tekken7')}
                                onParticipate={handleParticipation}
                            />
                        </Tab>
                        <Tab tabClassName="Participation__Tab__Item" eventKey="street fighter 5"
                            title={<span>Street Fighter 5 {hasRegisteredToGame(userData as Member, 'world cup', 'streetfighter5') ? <i className="fa fa-check-circle"></i> : ''}</span>}>
                            <Participation
                                gameName="street fighter 5"
                                videoUrl={require('../videos/Street.mp4')}
                                gameDescription={t('Street5')}
                                onParticipate={handleParticipation}
                            />
                        </Tab>
                    </Tabs>
                </Tab>

            </Tabs>

        </section>
        <Modal style={{ backdropFilter: 'blur(2px)', backgroundColor: '#00000040' }} backdrop="static" centered
            keyboard={false} show={show} onHide={handleClose}>
            <Modal.Header closeButton={!onSending}>
                <Modal.Title>{t('Participation')} <span className="text-capitalize">{competitionKey === 'world cup' ? t('WorldCup') : t('UniversityGames')}</span> 2023 <span className="text-yellow text-capitalize">{gameKey}</span> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <label htmlFor="message">{t('Message')}</label>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="message" rows={4} className="form-control bg-dark text-light"></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={onSending} variant="secondary" onClick={handleClose}>
                    {t('Close')}
                </Button>
                <button disabled={onSending || message.length < 10} className="esport esport-btn-normal w-25 p-1" onClick={handleSubmit}>
                    {!onSending ? t('send') : t('sending...')}
                </button>
            </Modal.Footer>
        </Modal>
    </AdminContainer>
}

export default ParticipationPage;