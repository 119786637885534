import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop } from 'react-image-crop';
import '../styles/CropImageModal.css';



export interface CropImageModalSaveOptions { blob: Blob | null, base64: string };

interface CropImageModalOptions {
    open: boolean,
    image: string,
    onLoad?: (_: any) => void,
    onChange?: (_: any) => void,
    onSave: ({ blob, base64 }: CropImageModalSaveOptions) => any,
    onClose: () => void,
}

const CropImageModal = ({ open, image, onLoad, onChange, onSave, onClose }: CropImageModalOptions) => {
    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: 250,
        height: 250,
        aspect: 1
    });

    const { t } = useTranslation();

    const [cropData, setCropData] = useState<HTMLImageElement | null>(null);


    const handleSave = (e: any) => {
        try {
            const canvas = document.createElement("canvas");
            if (!cropData) return;
            const scaleX = cropData.naturalWidth / cropData.width;
            const scaleY = cropData.naturalHeight / cropData.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            if (!ctx) return;
            ctx.drawImage(
                cropData,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            const base64Image = canvas.toDataURL("image/jpeg", 1);

            canvas.toBlob(function (blob) {
                if (base64Image !== "data:,") {
                    onSave({ "blob": blob, base64: base64Image });
                }
            });

        } catch (e) {
            console.log(e);
        }
    }

    const handleClose = () => {
        onClose()
    }

    const handleLoad = (data: HTMLImageElement) => {
        setCropData(data);
    }

    return <Modal className='cropModal'
        backdrop="static"
        keyboard={false}
        centered
        closeButton
        show={true}
        onHide={handleClose}>
        <div className=''>
            <ReactCrop
                src={image}
                onImageLoaded={handleLoad}
                crop={crop as Crop}
                onChange={(crop: Crop, _: any) => setCrop(crop as any)}
                circularCrop={true}
            />
        </div>

        <div className="d-flex justify-content-end align-item-end gap-3 m-2">
            <button type="button" className="esport esport-btn-normal w-25" onClick={handleSave} >{t('Validate')}</button>
        </div>
    </Modal>
}

export default CropImageModal;
