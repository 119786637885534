import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageViewer from 'react-simple-image-viewer';
import HomePageAbout from "../component/home/HomePageAbout";
import HomePageGame from "../component/home/HomePageGame";
import HomePageLastEdition from "../component/home/HomePageLastEdition";
import HomePageMain from "../component/home/HomePageMain";
import Navbar from "../component/navigation/Navbar";
import '../styles/page/HomePage.css';
import { Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import useForm from "../hook/useForm";
import { UserService } from "../services/UserService";

const HomePage = () => {
    const { t } = useTranslation();
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [message, setMessage] = useState<string | null>(null)
    const [images, setImages] = useState(
        Array(23).fill(0).map((_, key) => {
            return require(`../images/last-editions/${key + 1}.jpeg`)
        }).concat(Array(18).fill(0).map((_, key) => {
            return require(`../images/last-editions/${key + 24}.jpg`)
        }))
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get('reset_token'));
    const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
        { token: token, password: '', __password: '' },
        (name: string, value: string) => {
            if (name === 'password') {
                if (value.length < 6) return 'password length must be greater than 6';
            }
            if (name === '__password') {
                if (formValues.password !== value) return 'the passwords are different !';
            }
            return null;
        }
    );
    const navigate = useNavigate();

    const handleHide = () => {
        setToken(null)
    }

    const navigateInTo = function (this: HTMLElement, ev: MouseEvent): any {
        const targetSelector = this.getAttribute('data-scroll-to')

        if (targetSelector)
            document.querySelector(targetSelector)?.scrollIntoView();
    }

    const openImageViewer = useCallback((index: SetStateAction<number>) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const __handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const isSubmittable = handleSubmit(e);
        if (isSubmittable) {
            e.target.setAttribute("disabled", 'true');
            e.target.innerHTML = '<div class="spinner-grow spinner-grow-sm" role="status"><span class="visually-hidden">Loading...</span></div>';

            UserService.changePassword(formValues).
                then((json) => {

                    if (json.data.errors) {
                        setErrors(json.data.errors)
                    } else {
                        setMessage(t('Your password has been successfully changed ! we will redirect you to the login page...'))
                        setTimeout(() => {
                            // this will navigate to auth form
                            localStorage.setItem('navigateToAuth', 'true');
                            // this will show form login first
                            window.location.href = '/?form=login';
                        }, 1000);
                    }
                }).catch((err) => {
                    const data = err.response.data
                    if (data.errors) {
                        setErrors(data.errors);
                    }
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = t('reset');
                });
        } else {
            e.target.removeAttribute("disabled");
            e.target.innerHTML = t('reset');
        }
    }

    useEffect(() => {
        document.querySelectorAll('[data-scroll-to]').forEach((node, _, __) => {
            (node as HTMLElement).addEventListener('click', navigateInTo);
        });

        return () => {
            document.querySelectorAll('[data-scroll-to]').forEach((node, _, __) => {
                (node as HTMLElement).removeEventListener('click', navigateInTo);
            });
        }
    }, []);

    useEffect(() => {
        const navigateToAuth = localStorage.getItem('navigateToAuth');

        if (navigateToAuth) {
            (document.querySelector('.nav-link.toAuthForm') as HTMLSpanElement).click();
            localStorage.removeItem('navigateToAuth');
        }
    }, []);

    useEffect(() => {
        if (token) {

        }
    }, [token])


    return <div className="p-0 m-0">
        <div className="NavbarContainer w-100">
            <div className="custom-container">
                <Navbar />
            </div>
        </div>
        <HomePageMain />

        <HomePageAbout onOpenImageViewer={(index) => { openImageViewer(index - 1) }} />
        
        <HomePageLastEdition onOpenImageViewer={(index) => { openImageViewer(index - 1) }} />
        <HomePageGame />
        {isViewerOpen && (
            <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
            />
        )}

        <Modal
            backdrop="static"
            keyboard={false}
            centered
            onHide={handleHide}
            show={token !== null}>
            <Modal.Header closeButton>
                <Modal.Title>E-Sports Gaming 2023</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='p-2 m-2 py-4'>
                    <h3 className='mb-4'>{t('Reset your password')}</h3>
                    {message && <div className="alert alert-success"><i style={{ fontSize: '.8em' }} className='fa fa-check-circle'></i> {message}</div>}

                    {errors.length > 0 && <div className='mb-3'>
                        <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(errors[0])}</span>
                    </div>}

                    <div className="mb-3">
                        <input value={formValues.password} onChange={handleChange} type="password"
                            className="form-control" name="password" placeholder={t('password').toLowerCase()} />
                    </div>
                    {errors.password && <div className='mb-3'>
                        <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(errors.password)}</span>
                    </div>}

                    <div className="mb-3">
                        <input value={formValues.__password} onChange={handleChange} type="password"
                            className="form-control" name="__password" placeholder={t('re-enter password').toLowerCase()} />
                    </div>
                    {errors.__password && <div className='mb-3'>
                        <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(errors.__password)}</span>
                    </div>}

                    <button onClick={__handleSubmit} type='submit' className='esport esport-btn-normal  my-3 mt-4 w-100'>{t('reset')}</button>

                </form>
            </Modal.Body>
        </Modal>
    </div>
}

export { HomePage };