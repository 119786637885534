import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userReducer from "./slices/userSlice";
import memberReducer from "./slices/memberSlice";


export const store = configureStore({
    reducer: {
        user: userReducer,
        member: memberReducer
    },
    devTools: process.env.NODE_ENV === 'development',
})

setupListeners(store.dispatch)  