import { useTranslation } from 'react-i18next';
import '../../styles/component/HomeAdvantage.css';

const HomeAdvantage = () => {

    const { t } = useTranslation()

    return <div className="HomeAdvantage rounded">
        <div className='row'>
            <div className='joinImage col-lg-6 d-flex flex-column justify-content-center'>
                <h2 className='text-primary'> {t('advantages.title')}</h2>
                <ol>
                    {
                        Array(3).fill(0).map((_, key) => {
                            return <>
                                <li>{t(`advantages.t${key + 1}`)}</li>
                                <p>{t(`advantages.a${key + 1}`)}</p>
                            </>
                        })
                    }
                </ol>
            </div>
            <div className='advantagesImage col-lg-6 row mx-auto d-flex gap-2 align-items-center justify-content-center'>
                <img className='' alt='' src={`${process.env.REACT_APP_SERVER_BASE_URL}assets/esports-card-illustration.png`} />
                {/* <img className='' style={{ scale: '1' }} alt='' src={`${process.env.REACT_APP_SERVER_BASE_URL}assets/model-esports-verso.jpg`} /> */}
            </div>
        </div>
    </div>
}

export default HomeAdvantage;