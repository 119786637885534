import AdminContainer from "../component/AdminContainer";
import '../styles/page/PaymentPage.css';


const PaymentPage = () => {
    return <AdminContainer>
        <section className="HomePage__about__content border-5 bounce-animation w-100 d-flex flex-column rounded p-2 h-100">
            PaymentPage works !
        </section>
    </AdminContainer>
}

export default PaymentPage;