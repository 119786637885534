import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AdminContainer from '../component/AdminContainer';
import CircularLoader from '../component/loader/CircularLoader';
import { Card, Member } from '../models/Member';
import { UserState, generateUserCard } from '../redux/slices/userSlice';
import '../styles/page/HistoryPage.css';
import { formatPrice, parsePaymentMode, parseReadableDate } from '../utils/utils';
import axios from 'axios';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import { AnyAction } from '@reduxjs/toolkit';

const HistoryPage = () => {
    const userData = useSelector<Record<string, UserState>, UserState>((state) => state.user).data;
    const [q, setQ] = useState<string | null>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const historyDataColumns = [
        // {
        //     name: 'Profile',
        //     selector: (row: Card) => {
        //         if (row.image) {
        //             return <img src={userData?.image as string} className='w-50 p-2' style={{ borderRadius: '50%' }} alt={userData?.first_name} />
        //         }
        //         return <small className="text-muted text-white-50 small" style={{ userSelect: 'none' }}>non renseignée</small>
        //     }
        // },
        // {
        //     name: 'Nom(s) et Prénom(s)',
        //     selector: (row: Card) => { return `${userData?.last_name + ' ' + userData?.first_name}` },
        //     sortable: true,
        // },
        {
            name: t('Bought on'),
            selector: (row: Card) => { return parseReadableDate(row.created_at, true, true) },
            sortable: true,
            sortFunction: (a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => {
                let aDate = new Date(a.created_at)
                let bDate = new Date(b.created_at)
                return (aDate as unknown as number) - (bDate as unknown as number);
            }
        },
        {
            name: ('Expiration'),
            selector: (row: Card) => {
                const expiresAt = new Date(row.created_at);
                expiresAt.setFullYear(expiresAt.getFullYear() + 1)
                return <span className='text-warning'>{parseReadableDate(expiresAt, true, true)}</span>
            },
            sortable: true,
            sortFunction: (a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => {
                let aDate = new Date(a.created_at)
                let bDate = new Date(b.created_at)
                return (aDate as unknown as number) - (bDate as unknown as number);
            }
        },
        {
            name: t('rendered'),
            selector: (row: Card) => {
                return <div className='p-1 gap-2 d-flex justify-content-center'>
                    <img className='img-fluid col-3' src={row?.image.recto ?? '#'} alt={userData?.last_name} />
                    <img className='img-fluid col-3' src={row?.image.verso ?? '#'} alt={userData?.last_name} />
                </div>
            }
        },
        {
            name: t('Price'),
            selector: (row: Card) => { return formatPrice(10000) },
            sortable: true,
        },
        {
            name: t('payment method'),
            selector: (row: Card) => {
                return parsePaymentMode(row.payment.payment_method)
            },
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row: Card) => {
                return <div className="d-flex justify-content-around w-100">
                    {/* <button onClick={() => { setImageViewer(row.image); setSeasonViewer(getModelById(cardData, row.card_id).verso); }} title="visualisez votre carte" style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }} className="actionBtn"><i className="fa fa-eye" aria-hidden="true"></i></button> */}
                    <button title="téléchargez votre carte" onClick={(e) => handleDownloadCard(e, row)} style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }} className="actionBtn">download</button>
                    <button title="régénérez votre carte" onClick={(e) => handleGenerateCard(e, row)} style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }} className="actionBtn mx-1">generate</button>
                </div>
            }
        }
    ];

    const customTextFilter = (data: any, search: string | null) => {
        if (search === null) {
            return data
        }
        search = search.toLowerCase()
        return data.filter((val: { first_name: string; last_name: string; }, key: any) => {
            return val.first_name.toLowerCase().includes(search as string) || val.last_name.toLowerCase().includes(search as string)
        })
    }

    const handleDownloadCardPDF = async (e: any, card: Card) => {
        let reader1 = new FileReader();
        let reader2 = new FileReader();

        const doc = new jsPDF({
            orientation: 'p',
            format: [326, 503],
        });

        reader1.onloadend = function () {
            doc.addImage(reader1.result as string, 'png', 0, 0, 326, 503)
        }

        reader2.onloadend = function () {
            doc.addPage([326, 503], 'p')
            doc.addImage(reader2.result as string, 'png', 0, 0, 326, 503)
            doc.save('esports-card.pdf')
        }

        // download card image recto
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
            axios({
                url: `${process.env.REACT_APP_API_BASE_URL}download-card/recto/${card?.id}`,
                method: 'get',
                headers: {
                    "X-CSRF-TOKEN": response.data
                },
                responseType: 'blob'
            }).then(async (res) => {
                reader1.readAsDataURL(res.data);
                // download card image verso
                await axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
                    axios({
                        url: `${process.env.REACT_APP_API_BASE_URL}download-card/verso/${card?.id}`,
                        method: 'get',
                        headers: {
                            "X-CSRF-TOKEN": response.data
                        },
                        responseType: 'blob'
                    }).then((res) => {
                        reader2.readAsDataURL(res.data);
                    }).finally(() => {
                        e.target.innerHTML = 'download';
                    })
                });
            }).finally(() => {
            })
        });

    }

    const handleGenerateCard = (e: any, card: Card) => {
        e.target.innerHTML = 'generating...';
        e.target.setAttribute('disabled', 'true');

        dispatch(generateUserCard(card?.id) as unknown as AnyAction)
            .unwrap()
            .then((data: any) => {
                toast.success(
                    "Votre carte a été générée.",
                    {
                        autoClose: false
                    }
                )
            })
            .catch((err: any) => {
                toast.error('Erreur lors de la génération, veuillez réessayer, si ce problème persite veuillez nous contacter.',
                    {
                        autoClose: false
                    })
            })
            .finally(() => {
                e.target.removeAttribute('disabled');
                e.target.innerHTML = 'generate';
            });
    }


    const handleDownloadCard = async (e: any, card: Card) => {
        e.target.innerHTML = 'downloading...';
        e.target.setAttribute('disabled', 'true');
        await handleDownloadCardPDF(e, card);
    }


    return <AdminContainer>
        <section id="memberPageList" className="HomePage__about__content border-5 bounce-animation w-100 d-flex flex-column rounded p-2 h-100">

            {userData ? <>
                <div className="mb-3 d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <span className="w-100" style={{ fontSize: '2em' }}>{t('Transaction history')}</span>
                    {/* <div className="filter d-flex border ms-auto">
                        <div className="searchForm d-flex justify-content-center align-items-center">
                            <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQ(e.target.value)} id="search" type={'text'} placeholder="Rechercher" />
                            <div className="mx-3"><i className="fa fa-search" /></div>
                        </div>
                    </div> */}
                </div>
                <DataTable className='CustomDataTable'
                    columns={historyDataColumns as TableColumn<Card>[]}
                    data={customTextFilter(userData.card ?? [], q)}
                    striped
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                />
            </> : <CircularLoader />}
        </section>
    </AdminContainer>
}

export default HistoryPage;