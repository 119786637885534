import { LoginForm } from "./LoginForm"
import { RegisterForm } from "./RegisterForm"
import '../../styles/component/AuthForm.css'
import { ForgotPasswordForm } from "./ForgotPasswordForm"
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from 'react';

export interface FormOptions {
    onToggle: (form: string) => void,
}

const AuthForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form, setForm] = useState(searchParams.get('form'));

    const handleToggleForm = (form: string) => {
        document.querySelectorAll('.auth-form').forEach((form, _, __) => {
            form.classList.remove('auth-form-show');
        });
        document.querySelector(form)?.classList.add('auth-form-show');
    }

    useEffect(() => {
        if (form && form === 'login') {
            handleToggleForm('.login-form');
        }

    }, [])


    return <>
        <RegisterForm onToggle={handleToggleForm} />
        <LoginForm onToggle={handleToggleForm} />
        <ForgotPasswordForm onToggle={handleToggleForm} />
    </>
}

export { AuthForm };
