import { LegacyRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, UserState } from '../redux/slices/userSlice';
import Box from './Box';
import CircularLoader from './loader/CircularLoader';
import SideBar from './navigation/SideBar';
import TopBar from './navigation/TopBar';
import '../styles/AdminContainer.css';
import useAuthToken from '../hook/useAuthToken';
import { AnyAction } from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminContainer = ({ ...props }) => {
    const userData = useSelector<Record<string, UserState>, UserState>((state) => state.user).data;
    const authToken = useAuthToken();
    const dispatch = useDispatch();
    const sideBarRef = useRef<HTMLDivElement>();
    const contentRef = useRef<HTMLDivElement>();
    const navigate = useNavigate();

    const handleToggleSideBar = (e: any) => {
        if (sideBarRef && sideBarRef.current)
            if (sideBarRef.current.classList.contains('open')) {
                sideBarRef.current.classList.remove('open');
            } else {
                sideBarRef.current.classList.add('open');
            }
    }

    const handleContentClick = (e: { clientX: number; }) => {
        if (sideBarRef && sideBarRef.current)
            if (window.innerWidth <= 1200 && e.clientX > 250) {
                sideBarRef.current.classList.remove('open');
            }
    }

    useEffect(() => {
        if (!authToken) {
            navigate('/')
        }
        if (!userData) {
            dispatch(getCurrentUser() as unknown as AnyAction).unwrap().then((data: { active: string; }) => {

                if (!(data && parseInt(data.active) === 1)) {
                    navigate('/');
                }
            }).catch((err: any) => 0);
        }
        if (window.outerWidth <= 1200 && sideBarRef.current) {
            sideBarRef.current.classList.remove('open');
        }
    }, [authToken, dispatch, navigate, userData]);

    return <div className='AdminContainer' onClick={handleContentClick}>
        {!userData && <Box content={<CircularLoader />} />}
        {userData && <div className='AdminContainer__child d-flex'>
            <div ref={sideBarRef as LegacyRef<HTMLDivElement>} className='AdminContainer__sideBar open shadow'>
                <SideBar admin={userData.admin} />
            </div>
            <div ref={contentRef as LegacyRef<HTMLDivElement>} className='AdminContainer__content p-2 p-md-3 p-xl-4 w-100'>
                <TopBar onToggle={handleToggleSideBar} user={userData} />
                <div className='BottomContent mt-5'>
                    {props.children}
                </div>
            </div>
        </div>}
        <ToastContainer position='bottom-right' theme='dark'/>
    </div>;
}

export default AdminContainer;