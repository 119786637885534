import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Member } from "../../models/Member";
import { UserService } from "../../services/UserService";

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

export interface MemberState {
    data: Member[] | null;
    loading: boolean;
    error: string | null;
}


const initialState: MemberState = {
    data: null,
    loading: false,
    error: null
};

export const getMembers = createAsyncThunk(
    "member/all",
    async () => {
        const res = await UserService.getAll();
        return res.data as Member[];
    }
);

const memberSlice = createSlice({
    name: "member",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMembers.fulfilled, (state, action) => {
                state.data = action.payload;
            })
    },
});

export default memberSlice.reducer;
