import '../../styles/SideBar.css';
import SideBarItem from './SideBarItem';
import { useSelector } from 'react-redux';
import { Member } from '../../models/Member';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


const SideBar = ({ admin = false, ...props }) => {
    const userData = useSelector<Record<string, Member>, Member>((state) => state.user);

    const { t } = useTranslation();

    const SideBarRouter = [
        {
            path: '/dashboard/card',
            icon: <i className="fa fa-credit-card"></i>,
            title: t('Cards'),
            disable: false
        },
        {
            path: '/dashboard/profile',
            icon: <i className="fa fa-user-check"></i>,
            title: t('Profile'),
            disable: false
        },
        {
            path: '/dashboard/participation',
            icon: <i className="fa fa-trophy"></i>,
            title: 'Participation',
            disable: false
        },
        {
            path: '/dashboard/member',
            icon: <i className="fa fa-user-group"></i>,
            title: t('Member'),
            disable: false
        },
        {
            path: '/dashboard/history',
            icon: <i className="fa fa-sync"></i>,
            title: t('History'),
            disable: false
        },
        {
            path: '/dashboard/team',
            icon: <i className="fa fa-users"></i>,
            title: t('Team'),
            disable: false
        }
    ]

    const applySuperAdminRouteRestrictions = (routes: (typeof SideBarRouter), user: Member) => {
        const restrictedRoutes = [
            '/dashboard',
            '/member',
            '/complaint'
        ];
        const superAdmin = user.admin
        if (!superAdmin) {
            return routes.filter((route) => {
                return restrictedRoutes.indexOf(route.path) === -1
            })
        }
        if(user.status !== 'Team'){
            return routes.filter((route) => {
                return route.path !== '/dashboard/team'
            })
        }
        return routes
    }


    return <div className='SideBar'>
        <div className='SideBar__brand py-1 d-flex justify-content-center align-items-center'>
            <img alt='E-sports' src={require('../../images/logo.png')} />
        </div>
        <div className='SideBar__navigator d-flex flex-column gap-3'>
            {
                applySuperAdminRouteRestrictions(SideBarRouter, userData).map(({ path, icon, title, disable }, index) => {
                    return <SideBarItem key={path} to={path} icon={icon} title={title} disable={disable} />
                })
            }
        </div>
        <div className='poweredBy'>{t('Powered by')} <a className='text-yellow' href='https://www.mentalists.ca/'>Mentalists</a></div>
    </div>
}

export default SideBar;