import { CSSProperties } from 'react'
import '../../styles/component/ImageButton.css'

type ImageButtonProps = {
    image: string,
    textButton?: string,
    borderBackgroundImage?: string,
    style?: CSSProperties,
    noButton?: Boolean,
    onClick: () => void,
    className?: string,
}

const ImageButtonX = ({ image, textButton, borderBackgroundImage, style, noButton, onClick, className }: ImageButtonProps) => {

    const defaultStyle: CSSProperties = {
        width: '270.4px',
        height: '178.9px',
        backgroundImage: `url('../../images/03.png'),url(${image})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
    }

    return <div onClick={onClick} className={"ImageButton " + className} style={{ ...defaultStyle, ...style }}>
        {!noButton && <button className='ImageButton__action esport esport-btn-normal mb-3 px-3'>Découvres</button>}
    </div>
}

const ImageButton = ({ image, textButton, borderBackgroundImage, style, noButton, onClick, className }: ImageButtonProps) => {


    return <div onClick={onClick} className={"ImageTop"} style={style}>
        <img src={image} alt='' className={className} />
    </div>
}

export {
    ImageButtonX,
    ImageButton
}