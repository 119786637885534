import { useTranslation } from 'react-i18next';


const Footer = () => {

    const { t } = useTranslation();

    return <footer className="w-100 mt-md-3 mt-lg-5 text-center d-flex gap-2 flex-column justify-content-center align-items-center">
        <div className="d-flex w-100 mt-md-0 mt-lg-5 justify-content-around align-items-center">
            <div className='custom-container mt-0 d-flex flex-column flex-md-row'>
            <img className="img-fluid col-4 col-md-2 mx-auto d-md-block" alt="E-sports" src={require('../images/logo.png')} />

                <div className='d-flex mx-auto col-12 col-md-6 justify-content-between align-items-end align-items-md-center'>
                    <div>
                        <span>{t('Localization')}</span>
                        <p>Douala, {t('Cameroon')}<br /> Bonamoussadi</p>
                    </div>
                    <div className="Footer__contact">
                        <span>Contact</span>
                        <p><a className='text-yellow' href="tel:+237695072321">+237 6 95 07 23 21</a><br />
                            <a className='text-yellow' href="tel:+237694393795">+237 6 94 39 37 95</a><br />
                            <a className='text-yellow' href="mailto:info@cameroun-esport.com">info@cameroun-esport.com</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="Footer__bottom p-4 w-100">© 2023 {t('Federation')} | {t('Powered by')} <a className="ms-1 text-yellow" href="https://www.mentalists.ca/"> Mentalists</a></div>
    </footer>
        ;
}

export default Footer;