export const parseReadableDate = (date: string | number | Date, jsx = true, onlyDay = false) => {
    let month: number | string = getMonth(date),
        dateDay: number | string = (new Date(date)).getDate(),
        year: number | string = (new Date(date)).getFullYear(),
        hour: number | string = (new Date(date)).getHours(),
        minutes: number | string = (new Date(date)).getMinutes()

    minutes = minutes < 10 ? `0${minutes}` : minutes
    hour = hour < 10 ? `0${hour}` : hour
    let output = dateDay + ' ' + month + ' ' + year;

    if (!onlyDay) {
        output += ' ' +
            ' à ' +
            hour + 'h' + minutes
    }

    if (!jsx) return output

    return <span title={output}> {output} </span>

}

export const parsePaymentMode = (mode: string) => {
    switch (mode) {
        case 'cc':
            return 'carte de crédit'
        case 'om':
            return 'orange money'
        case 'momo':
            return 'mtn money'
        default:
            return null
    }

}

export const getDayweek = (date: string | number | Date) => {
    let ladate = new Date(date)
    let tab_jour = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const lng = localStorage.getItem('i18nextLng') ?? navigator.language;
    if (lng !== 'fr-FR') {
        tab_jour = ["Sunday Monday Tuesday Wednesday Thursday Friday Saturday"];
    }
    return tab_jour[ladate.getDay()]
}

export const getMonth = (date: string | number | Date) => {
    let ladate = new Date(date)
    let tab_mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const lng = localStorage.getItem('i18nextLng') ?? navigator.language;
    if (lng !== 'fr-FR') {
        tab_mois = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    return tab_mois[ladate.getMonth()]
}

export const formatDate = (date: Date) => {
    let year = '' + date.getFullYear(),
        month = (date.getMonth() + 1) + '',
        day = '' + date.getDate()
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatPrice = (price: number | bigint) => {
    //  return price
    return new Intl.NumberFormat('cm-CM', { style: 'currency', currency: 'XAF' }).format(price)
}

export const getHostName = () => {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;
  
    return `${protocol}//${domain}${port ? ':' + port : ""}`
  }