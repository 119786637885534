
const SelectCity = () => {
    return <>
        <optgroup label="Adamawa Region">
            <option value="Ngaoundéré">Ngaoundéré</option>
            <option value="Yola">Yola</option>
        </optgroup>
        <optgroup label="Centre Region">
            <option value="Yaoundé">Yaoundé</option>
            <option value="Ebolowa">Ebolowa</option>
            <option value="Mbalmayo">Mbalmayo</option>
        </optgroup>
        <optgroup label="East Region">
            <option value="Bertoua">Bertoua</option>
            <option value="Batouri">Batouri</option>
            <option value="Abong Mbang">Abong Mbang</option>
        </optgroup>
        <optgroup label="Far North Region">
            <option value="Maroua">Maroua</option>
            <option value="Mokolo">Mokolo</option>
            <option value="Yagoua">Yagoua</option>
        </optgroup>
        <optgroup label="Littoral Region">
            <option value="Douala">Douala</option>
            <option value="Buea">Buea</option>
            <option value="Limbe">Limbe</option>
        </optgroup>
        <optgroup label="North Region">
            <option value="Garoua">Garoua</option>
            <option value="Guider">Guider</option>
            <option value="Mora">Mora</option>
        </optgroup>
        <optgroup label="Northwest Region">
            <option value="Bamenda">Bamenda</option>
            <option value="Kumbo">Kumbo</option>
            <option value="Ndop">Ndop</option>
        </optgroup>
        <optgroup label="South Region">
            <option value="Ebolowa">Ebolowa</option>
            <option value="Kribi">Kribi</option>
            <option value="Sangmelima">Sangmelima</option>
        </optgroup>
        <optgroup label="Southwest Region">
            <option value="Buea">Buea</option>
            <option value="Limbe">Limbe</option>
            <option value="Mutengene">Mutengene</option>
        </optgroup>
        <optgroup label="West Region">
            <option value="Bafoussam">Bafoussam</option>
            <option value="Dschang">Dschang</option>
            <option value="Foumban">Foumban</option>
        </optgroup>
    </>

}

export { SelectCity }